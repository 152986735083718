<template>
  <div>
    <b-card>
      <h1 class="ml-2 mb-2">Size Ulaşanlar</h1>
      <!-- Cards -->
      <b-row>
        <b-col v-for="(item, index) in TabloItems" :key="index" md="12">
          <b-card class="mb-2 cardStyle">
            <b-card-body>
              <b-row>
                <b-col md="3">
                  <h5>Email</h5>
                  <hr />
                  <div v-if="item.email">
                    <b-card-text class="titleStyle"
                      >{{ item.email }}
                    </b-card-text>
                  </div>
                </b-col>
                <b-col md="2">
                  <h5>İsim</h5>
                  <hr />
                  <div v-if="item.firstName">
                    <b-card-text>
                      <div>{{ item.firstName }}</div>
                    </b-card-text>
                  </div>
                </b-col>

                <b-col md="2">
                  <h5>Soyisim</h5>
                  <hr />
                  <div v-if="item.lastName">
                    <b-card-text>
                      <div>{{ item.lastName }}</div>
                    </b-card-text>
                  </div>
                </b-col>
                <b-col md="2">
                  <h5>Telefon</h5>
                  <hr />
                  <div v-if="item.phone">
                    <b-card-text>
                      <div>{{ item.phone }}</div>
                    </b-card-text>
                  </div>
                </b-col>
                <b-col md="2">
                  <h5>Mesaj</h5>
                  <hr />
                  <div v-if="item.message">
                    <b-card-text>
                      <div>{{ item.message }}</div>
                    </b-card-text>
                  </div>
                </b-col>
                <!-- Delete Button -->
                <b-col md="1">
                  <b-button
                    @click="deleteModal(item)"
                    v-b-modal.modal-danger
                    variant="gradient-danger"
                    class="btn-icon"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- Delete Modal-->
      <b-modal
        id="modal-danger"
        ok-only
        ok-variant="danger"
        ok-title="Ok"
        no-close-on-backdrop
        modal-class="modal-danger"
        centered
        @ok="deleteData()"
      >
        <b-card-text class="mt-1">
          SİLMEK İSTEDİĞİNİZE EMİN MİSİNİZ?
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardText,
  BCardTitle,
  BForm,
  BMedia,
  BCardBody,
  BRow,
  BModal,
  BAvatar,
  BMediaBody,
  BCol,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BMediaAside,
  BProgress,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import Ripple from "vue-ripple-directive";

import { heightTransition } from "@core/mixins/ui/transition";
import axios from "axios";

export default {
  components: {
    BCard,
    BTable,
    BModal,
    BCardTitle,
    BCardFooter,
    BCardText,
    BForm,
    BLink,
    BMediaAside,
    BImg,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    BMediaBody,
    BButton,
    BFormGroup,
    BFormInput,
    BProgress,
    BBadge,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      TabloItems: [],
      id:null,
      snowOption: {
        theme: "snow",
      },
    };
  },

  created() {
    this.getAllData();
    this.$router.beforeEach(async (to, from, next) => {
      const isAuthenticated = localStorage.getItem("token");

      if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !isAuthenticated
      ) {
        // Bu sayfa kimlik doğrulama gerektiriyor ve kullanıcı giriş yapmamışsa
        next("/login");
      } else {
        next();
      }
    });
  },

  methods: {
    //
    getAllData() {
      axios
        .get("/api/Communications/GetList")
        .then((result) => {
          if (result.data.success) {
            this.TabloItems = result.data.data;
           // console.log(this.TabloItems);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Get Catch'e düştü", error);
        });
    },

    deleteModal(item) {
      this.id = item.id;
    },
    //delete/Post
    deleteData() {
      axios
        .post(`/api/Communications/Delete/${this.id}`)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("Mesaj Silindi", {
              title: `Size Ulaşanlar`,
              variant: "success",
            });

            this.saveImg(result.data.data.id, this.blogFile); // kontrol et
          } else {
            this.$bvToast.toast("Mesaj Silinemedi", {
              title: `Size Ulaşanlar`,
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Delete Hatası", error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style scoped>
.cardStyle {
  border: 1px solid grey;
  background-color: rgba(140, 235, 153, 0.13);
}
.titleStyle {
  text-align: left;
  color: black;
}
</style>
